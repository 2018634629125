import './App.css';

import GiftBoxAnimation from '../components/giftbox/GiftBoxAnimation';

function App() {
  return (
    <div className='xmas'>
      <h1>Frohe Weihnachten Lana, hier kannst du dir dein Geschenk abholen!</h1>
      <GiftBoxAnimation></GiftBoxAnimation>
    </div>
  );
}

export default App;
