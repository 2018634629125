import './Gift.scss';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Snowfall from 'react-snowfall'
import littleTokyo from '../assets/tokyo.jpg';
import luna from '../assets/luna.png';
import coco from '../assets/coco.png';

function Gift() {
  return (
      <Carousel autoFocus={true} showThumbs={false} showStatus={false} useKeyboardArrows className="presentation-mode">
        <div key="content-1" className="my-slide primary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Brauchst du noch Hinweise?</h2>
        </div>
        <div key="content-1" className="my-slide secondary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Essen werden wir...</h2>
        </div>
        <div>
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <img src="https://nipporisushi.com/wp-content/uploads/2020/03/background-1.jpg" />
        </div>
        <div key="content-1" className="my-slide primary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>und...</h2>
        </div>
        <div>
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <img src="https://www.tastingtable.com/img/gallery/what-makes-japanese-ramen-different-from-chinese-ramen/l-intro-1655408349.jpg" />
        </div>
        <div key="content-1" className="my-slide secondary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Übernachten werden wir auch!</h2>
        </div>
        <div key="content-1" className="my-slide primary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Immer noch nicht sicher?</h2>
        </div>
        <div key="content-1" className="my-slide secondary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Wir fliegen nach...</h2>
        </div>
        <div>
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <img src="https://images.ctfassets.net/bth3mlrehms2/2cCxpmVnFuv9cwMkr2NsqO/0f41647ab438ea7efa23b0d260123a50/Japan_Tokio_Skyline_und_Mt._Fuji.jpg?w=3721&h=2093&fl=progressive&q=50&fm=jpg" />
        </div>
        <div key="content-1" className="my-slide primary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Upps falsches Bild, und ich meinte natürlich fahren nicht fliegen...</h2>
        </div>
        <div>
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <img src={littleTokyo} />
        </div>
        <div key="content-1" className="my-slide secondary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Wir gehen für 2 Nächte nach Little Tokyo in Düsseldorf! <br /> Hoffe du freust dich trotzdem auch wenn es nicht das echte ist 😂</h2>
        </div>
        <div key="content-1" className="my-slide primary">
          <Snowfall
            // Controls the number of snowflakes that are created (default 150)
            snowflakeCount={500}
            radius={[2, 5]}
          />
          <h2>Hier schonmal 2 Hotel Vorschläge, Datum können wir noch besprechen!</h2>
          <div className='imageContainer'>
            <a href="https://www.booking.com/hotel/de/ruby-luna-dusseldorf.en-gb.html" target="_blank"><img src={luna} /></a>
            <a href="https://www.booking.com/hotel/de/ruby-coco-dusseldorf.en-gb.html" target="_blank" ><img src={coco} /></a>
          </div>
        </div>
      </Carousel>
  );
}

export default Gift;
