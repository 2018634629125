import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './pages/App';
import Snowfall from 'react-snowfall'
import Gift from './pages/Gift';

export default function Router() {
  return (
    <BrowserRouter>
     <Snowfall
        // Controls the number of snowflakes that are created (default 150)
        snowflakeCount={500}
        radius={[2, 5]}
      />
      <Routes>
          <Route index element={<App />} />
          <Route path="gift" element={<Gift />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Router />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
